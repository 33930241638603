.footer {
    background-color: #292c2f;
    width: 100%;
    color: whitesmoke;
    /* https://stackoverflow.com/a/18935218/1098564 */
    box-shadow: 0 50vh 0 50vh #292c2f;
    padding-top: 15px;
}

.footer a { 
    color: white;
    font-weight: bold;
}
.footer a:hover { color: whitesmoke; text-decoration: none; }

.copyright a { color: whitesmoke; text-decoration: none; }
.copyright a:link { color: whitesmoke; text-decoration: none; }
.copyright a:hover { color: whitesmoke; text-decoration: none; }
.copyright a:visited { color: whitesmoke; text-decoration: none; }
.copyright a:active { color: whitesmoke; text-decoration: none; }
.copyright {
    text-align: center;
}

.footer .code {
    font-family: Monaco, Consolas, 'Courier New', monospace;
    background: black;
    padding: 10px;
}

.footer .footer-icons{
	margin-top: 25px;
}

.footer .footer-icons a{
	display: inline-block;
	width: 35px;
	height: 35px;
	cursor: pointer;
	/* background-color:  #33383b; */
	border-radius: 2px;

	font-size: 20px;
	color: #ffffff;
	text-align: center;
	line-height: 35px;

	margin-right: 3px;
	margin-bottom: 5px;
}