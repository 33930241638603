.code-area {
    width: 100%;
    padding-top: 15px;
}

.code-area label {
    font-size: large;
    font-weight: bold;
}
.code-area #inputSelectMain, #outputSelectMain {
    height: 200px;
    font-family: 
    Monaco, Consolas, 'Courier New', monospace;
    background-color: whitesmoke
}